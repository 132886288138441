import palette from "../theme/palette";
import { styled } from "@mui/system";
import { Link } from "gatsby";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

export const SErrorContainer = styled(Box)({
  maxWidth: 313,
  width: "100%",
  display: "block",
  margin: "-200px auto 0px auto",

  "@media (max-width: 750px)": {
    margin: "30px auto 0px auto",
  },
});

export const SErrorContainer403 = styled(Box)({
  width: "100%",
  display: "block",
  margin: "30px auto 0px auto",
});

export const SErrorContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  "& h2.MuiTypography-root": {
    textAlign: "center",
    lineHeight: "56px",

    "@media (max-width: 575px)": {
      maxWidth: 279,
    },
  },

  "& p.MuiTypography-root": {
    textAlign: "center",
  },
});

export const SLink = styled(Link)({
  textDecoration: "none",
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

export const SErrorImg = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginTop: 36,

  "& svg": {
    height: "auto",
  },
});

export const SBtnHome = styled(Button)({
  "&.MuiButtonBase-root.MuiButton-root": {
    backgroundColor: palette.purpleBlue,
    color: palette.secondary,
    width: 264,
    height: 60,
    borderRadius: 8,
    marginTop: 36,
    fontSize: 16,
    lineHeight: "24px",
    textTransform: "capitalize",
    border: `1px solid ${palette.purpleBlue} `,

    "&:hover": {
      backgroundColor: palette.secondary,
      color: palette.purpleBlue,
    },

    "@media (max-width: 575px)": {
      width: "100%",
      marginTop: 20,
    },
  },
});

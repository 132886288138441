import React from "react";
import Layout from "../layout";
import Seo from "../components/seo";
import ErrorImg from "../assets/icons/Error403";
import {
  SBtnHome,
  SErrorContainer403,
  SErrorContent,
  SErrorImg,
  SLink,
} from "../styles/error";
import Typography from "@mui/material/Typography";

function NotFoundPage() {
  return (
    <Layout footerWrapper="footer-wrapper">
      <Seo title="403: Not authorized" />
      <div className="main-padding">
        <div className="main-wrapper">
          <SErrorImg>
            <ErrorImg />
          </SErrorImg>
          <SErrorContainer403>
            <SErrorContent>
              <Typography variant="h2">We are Sorry...</Typography>
              <Typography variant="body1">
                The page you’re trying to access has restricted access.
              </Typography>
              <SLink to="/">
                <SBtnHome>Back to Homepage</SBtnHome>
              </SLink>
            </SErrorContent>
          </SErrorContainer403>
        </div>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
